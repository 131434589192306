<div class="row">
  <div *ngIf="actuacion === '19' || actuacion === '20'" class="ps--footer">
    <em class="bi bi-info-circle-fill"></em>
    <strong>En caso de incidencia</strong>{{ mapfreText }}
  </div>
  <div *ngIf="(actuacion === '16' || actuacion === '99') && !checkPrescComGenerico() && !checkPrescBancaPrivada() && !checkPrescRentingAuto() && !checkPrescUniversidades()" class="ps--footer">
    <em class="bi bi-info-circle-fill"></em>
    <strong>En caso de incidencia</strong>{{ comercialesText }}
  </div>
</div>
