import { LogLevel } from "@darwin/config";

export const environment = {
  production: true,
  configLogLevel: LogLevel.ERROR,
};

export const ENDPOINTS = {
  login: "/login",
  log: "/log",
  datosPrescriptor: "/prescriber",
  datosFormulario: "/client",
  parametros: "/parameters",
  tipoCliente: "/client-types",
  tipoPersona: "/client/types/{clientType}/person-types",
  tipoProducto:
    "/client/types/{clientType}/person-types/{personType}/product_types",
  modeloAtencion:
    "/client/types/{clientType}/person-types/{personType}/product_types/{productType}/attention-model",
  tiposProducto: "/product-types",
  modelosAtencion: "/attention-model",
  estados: "/status",
  documentoConsentimiento: "/consent-doc",
  ordinales: "/office",
  facturaFiltro: "/document/by-filter",
  facturaDescarga: "/document",
  documentos: "/document-list?action={actuacion}",
  documentoDescarga: "/document/by-id?action={actuacion}&id={id}",
  documentLeido: "/document-read-list/{uid}",
  circuito: "/circuit",
  noticias: "/news?action={actuacion}",
  noticiasReducidas: "/new?action={actuacion}&id={id}",
  banners: "/banner?action={actuacion}",
  logos: "/header-footer?action={actuacion}",
  hagaseCliente: "/become-client",
  areaPersonal: "/prescriber/phone",
  altaPrescCorreos: "/correos/client",
  bajaLeads: "/cancelLead",
  horarioOficina: "/officeTime/{centro}",
  loginWallpaper: "/loginWallpaper",
  detallesLead: "/detailsLead",
  homeWallpaper: "/homeScreenWallpaper?prescriptorType={actuacion}",
  altaUsuario: "/prescriber/employee/create",
  bajaUsuario: "/prescriber/employee/delete",
  listadoUsuarios: "/prescriber/employee/findAll",
  perfilPrescriptor: "/excludedUser"
};

export const KEYS_SECONDARY_SEESSION = {
  token: "token",
  login: "userLoginData",
};

export const ROUTE_PATH = {
  login: "login",
  recoveryPass: "login/recovery",
  inicioProductos: "inicio/productos",
  areapersonal: "areapersonal",
  prescriptoresAlta: "presc/create",
  seguimiento: "presc/seguimiento",
  inicioPcomerciales: "presc-comerciales/inicio",
  factura: "presc-comerciales/facturas",
  circuito: "presc-comerciales/circuito",
  documentos: "presc-comerciales/documentos",
  noticias: "presc-comerciales/noticias",
  detalleNoticia: "presc-comerciales/detalleNoticias",
  prescAltaCorreos: "presc-correos/alta",
  prescAltaCorreosConsent: "presc-correos/consent",
  inicioPgenericos: "presc-genericos/inicio",
  prescDigital: "presc-digital/create",
  detallePrescripcion: "presc/detail",
  gestionEmpleados: "gestionEmpleados",
};

export const FORM_VALUES = {
  action: "22",
  contract: "eeeeeee",
  clientType: "2",
  personType: "2",
  productType: "3",
  productDeec: "descripcion",
  nif: "niff",
  documentType: "CIF",
  name: "",
  phone: "",
  email: "",
  attentionModel: "",
  dataProtection: "",
  status: "",
  dischargeDate: "",
  modificationDate: "",
};
