import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
// clase principal del modulo
export class LayoutService {
  private readonly _alertShow$ = new BehaviorSubject<boolean>(false);
  private readonly _headerShow$ = new BehaviorSubject<boolean>(false);
  private readonly _footerShow$ = new BehaviorSubject<boolean>(false);
  private readonly _alertOptions$ = new BehaviorSubject<{
    textDescription?: string;
    classIcon?: string;
    textBtnClose?: string;
  }>({});
  private readonly _resetPass$ = new BehaviorSubject<boolean>(false);

  alertIsShowed$ = this._alertShow$.asObservable();
  alertOptions$ = this._alertOptions$.asObservable();
  headerIsShowed$ = this._headerShow$.asObservable();
  footerIsShowed$ = this._footerShow$.asObservable();
  resetPassIsShowed$ = this._resetPass$.asObservable();

  /**
   * Metodo para mostrar los errores contralorados en pantalla completa
   * @param message string
   * @param message IErrorOptions
   */
  showAlert(options: {
    textDescription?: string;
    classIcon?: string;
    textBtnClose?: string;
  }) {
    this._alertShow$.next(true);
    this._alertOptions$.next(options);
  }

  /**
   * showHeader
   */
  showHeader() {
    this._headerShow$.next(true);
  }

  hideHeader() {
    this._headerShow$.next(false);
  }

  /**
   * mostrar footer
   */
  showFooter() {
    this._footerShow$.next(true);
  }
  /**
   * ocultar footer
   */
  hideFooter() {
    this._footerShow$.next(false);
  }

  /**
   * activar resetPass
   */
  showResetPass() {
    this._resetPass$.next(true);
  }

  /**
   * ocultar resetPass
   */
  hideResetPass() {
    this._resetPass$.next(false);
  }

  /**
   * Metodo para ocultar los errores contralorados en pantalla completa
   * @param message string
   * @param message IErrorOptions
   */
  hideAlert() {
    this._alertShow$.next(false);
    this._alertOptions$.next({});
  }
}
